import { IoIosArrowBack } from "react-icons/io";


export const ArchiveNavbar = () => {

    return (
        <>
         <div className="archive-nav">
            <a className="archive-navbar" href="/">
          <p>Eugene Roarz</p>

            </a>
         </div>
        </>
    )
}