import photography from "../assets/img/photo.png";
import carapp from "../assets/img/airbnb-5.png";
import slider2 from "../assets/img/easy-eat.jpg";
import restorant from "../assets/img/hotel.png";
import film from "../assets/img/filmp.png";
import ecommerce from "../assets/img/ecomm.jpg";
import fashion from "../assets/img/fashion.png";
import holt from "../assets/img/music.jpg";
import portfolio from "../assets/img/port.jpg";
import amicale from "../assets/img/amicale.jpg";
import realEstate from "../assets/img/amali.jpg";
import ride from "../assets/img/rent.png";


const products = [
    {
      title: "Product 1",
      link: "/product-1",
      thumbnail: holt,
    },
    {
      title: "Product 2",
      link: "/product-2",
      thumbnail: restorant,
    },
   
    {
        title: "Product 3",
        link: "/product-1",
        thumbnail: film,
      },
      {
        title: "Product 4",
        link: "/product-2",
        thumbnail: fashion,
      },
      {
        title: "Product 5",
        link: "/product-1",
        thumbnail: holt,
      },
      {
        title: "Product 6",
        link: "/product-2",
        thumbnail: portfolio,
      },
      {
        title: "Product 7",
        link: "/product-1",
        thumbnail: amicale,
      },
      {
        title: "Product 8",
        link: "/product-2",
        thumbnail: realEstate,
      },
      {
        title: "Product 9",
        link: "/product-2",
        thumbnail: ride,
      },
      {
        title: "Product 10",
        link: "/product-1",
        thumbnail: photography,
      },
      {
        title: "Product 11",
        link: "/product-2",
        thumbnail: fashion,
      },
      {
        title: "Product 12",
        link: "/product-2",
        thumbnail: fashion,
      },
      {
        title: "Product 13",
        link: "/product-1",
        thumbnail: photography,
      },
      {
        title: "Product 14",
        link: "/product-2",
        thumbnail: fashion,
      },
      {
        title: "Product 15",
        link: "/product-2",
        thumbnail: fashion,
      },
    
   
    ];

    export default products;

  