import React, { useEffect, useRef } from 'react';
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";
import gsap from 'gsap';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import photography from "../assets/img/halim3.PNG";
import carapp from "../assets/img/moffat-12.PNG";
import slider2 from "../assets/img/cr.PNG";
import museum from "../assets/img/halim2.PNG";
import fitness from "../assets/img/gym-web-6.PNG";
import givenchy from "../assets/img/drf.PNG";

const Myservices = () => {
  const linkRefs = useRef([]);
  const linkHoverRevealRefs = useRef([]);
  const linkImagesRefs = useRef([]);
  const textRefs = useRef([]);
  const servicesRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (index) => (e) => {
      linkHoverRevealRefs.current[index].style.opacity = 1;
      linkHoverRevealRefs.current[index].style.transform = `translate(${e.clientX - 300}px, - ${e.clientY / 3}px ) rotate(${e.clientX / 20}deg)`;
      linkImagesRefs.current[index].style.transform = 'scale(1, 1)';
    };

    const handleMouseLeave = (index) => () => {
      linkHoverRevealRefs.current[index].style.opacity = 0;
      linkHoverRevealRefs.current[index].style.transform = `translate(-50%, -50%)`;
      linkImagesRefs.current[index].style.transform = 'scale(0.8, 0.8)';
    };

    linkRefs.current.forEach((link, index) => {
      link.addEventListener('mousemove', handleMouseMove(index));
      link.addEventListener('mouseleave', handleMouseLeave(index));
    });

    return () => {
      linkRefs.current.forEach((link, index) => {
        link.removeEventListener('mousemove', handleMouseMove(index));
        link.removeEventListener('mouseleave', handleMouseLeave(index));
      });
    };
  }, []);

  const items = [
    { title: "Photography Awards", desc: "WEB DESIGN", img: slider2, path:"https://mac-milan.onrender.com" },
    { title: "Film Web", desc: "WEB DESIGN", img: carapp, path:"https://moffat-credits.onrender.com" },
    { title: "Museum ", desc: "WEB DESIGN", img: museum, path:"https://halim-museum.onrender.com" },
    { title: "Fashion", desc: "WEB DESIGN", img: givenchy, path:"https://nedland-culture.onrender.com" },
    { title: "Gym Web", desc: "WEB DESIGN", img: fitness, path:"https://fit-nexus.onrender.com" },
  ];

  useEffect(() => {
    const revealElements = servicesRef.current.querySelectorAll("[data-reveal]");

    const scrollReveal = () => {
      for (let i = 0; i < revealElements.length; i++) {
        const elementIsInScreen = revealElements[i].getBoundingClientRect().top < window.innerHeight / 1.15;

        if (elementIsInScreen) {
          revealElements[i].classList.add("revealed");
        } else {
          revealElements[i].classList.remove("revealed");
        }
      }
    };

    window.addEventListener("scroll", scrollReveal);
    scrollReveal(); // Initial check

    return () => {
      window.removeEventListener("scroll", scrollReveal);
    };
  }, []);

  return (
    <section className='services-container ' ref={servicesRef}>
      <div className='left-services'>
        <h1 className="falling-text" data-reveal>My Recent Work</h1>
        <p data-reveal>
  Here's some of the recent works I've done. From crafting responsive and visually appealing websites to developing complex web applications, I have honed my skills to deliver exceptional digital experiences. 
</p>

      </div>
      <div className='services-right'>
        <nav className='nav'>
          <ul>
            {items.map((item, index) => (
              <li key={index} data-reveal>
                <Link to={item.path} target="_blank" rel="noopener noreferrer" className='services-link'> 
                  <div className="link" ref={(el) => (linkRefs.current[index] = el)}>
                    <div >
                      <span className='link-title'>{item.title}</span>
                      <h2 className='link-desc'>{item.desc}</h2>
                    </div>
                    <MdOutlineSubdirectoryArrowRight className="arrow-icon" />
                    <div className="hover-reveal" ref={(el) => (linkHoverRevealRefs.current[index] = el)}>
                      <img src={item.img} className="hidden-img" alt={item.title} ref={(el) => (linkImagesRefs.current[index] = el)} />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default Myservices;
