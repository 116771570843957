import React, { useEffect, useState } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import { Link } from "react-router-dom";
import products from "../assets/ProductData";
import '../App.css'; // Import the custom CSS

export const HeroParallax = () => {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 784);

  useEffect(() => {
      const handleResize = () => {
          setIsDesktop(window.innerWidth > 784);
      };

      window.addEventListener('resize', handleResize);

      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  const firstRow = products.slice(0, 5);
  const secondRow = products.slice(5, 10);
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const springConfig = { stiffness: 100, damping: 30, bounce: 0 };

  const translateX = useSpring(
    useTransform(scrollYProgress, [0, 1], [0, 150]),
    springConfig
  );
  const translateXReverse = useSpring(
    useTransform(scrollYProgress, [0, 1], [0, -150]),
    springConfig
  );

  const height = useTransform(scrollYProgress, [0, 0.9], [17, 0]);
  const mediaHeight = useTransform(scrollYProgress, [0, 0.9], [15, 0]);

  return (
    <div
      ref={ref}
      className="parallax-wrapper "
    >
      <motion.div className="parallax-container1">
        {firstRow.map((product) => (
          <ProductCard
            product={product}
            translate={translateX}
            key={product.title}
          />
        ))}
      </motion.div>
      <motion.div className="parallax-container">
        {secondRow.map((product) => (
          <ProductCard
            product={product}
            translate={translateXReverse}
            key={product.title}
          />
        ))}
      </motion.div>
      <motion.div style={{ height: isDesktop ? height : mediaHeight }}  className="circleContainer">
        <div className="circle"></div>
      </motion.div>
    </div>
  );
};

export const ProductCard = ({ product, translate }) => {
  return (
    <motion.div
      style={{ x: translate }}
      whileHover={{ y: -5 }}
      key={product.title}
      className="group/product product-card h-96 w-[30rem] relative flex-shrink-0"
    >
      <Link to={product.link} className="block parallax-img-container group-hover/product:shadow-2xl">
        <img
          src={product.thumbnail}
          className="object-cover parallax-img object-left-top absolute h-full w-full inset-0"
          alt={product.title}
        />
      </Link>
      <div className="absolute inset-0 h-full w-full opacity-0 group-hover/product:opacity-80 bg-black pointer-events-none transition-opacity duration-300"></div>
      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover/product:opacity-100 text-white transition-opacity duration-300">
        <h2 className="text-xl">{product.title}</h2>
      </div>
    </motion.div>
  );
};
