import 'animate.css';
import aboutimg from "../assets/img/respect.gif";
import { VscDebugBreakpointLog } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Navbar } from "react-bootstrap";
import helloImg from "../assets/img/hello.gif";

export const Banner = () => {
  const description = useRef(null);
  const coverCenterRef = useRef(null);
  const yearRefs = [useRef(null), useRef(null)];
  const [isTextInView, setTextInView] = useState(false);
  const [areYearsInView, setYearsInView] = useState([false, false]);
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const [currentImage, setCurrentImage] = useState(aboutimg);

  const slideUp = {
    initial: {
      y: "100%",
    },
    open: (i) => ({
      y: "0%",
      transition: { duration: 0.5, delay: 0.01 * i },
    }),
    closed: {
      y: "100%",
      transition: { duration: 0.5 },
    },
  };

  useEffect(() => {
    const handleScroll = () => {
      const descriptionRect = description.current.getBoundingClientRect();
      setTextInView(descriptionRect.top < window.innerHeight / 1.15);

      setYearsInView(yearRefs.map(ref => ref.current.getBoundingClientRect().top < window.innerHeight / 1.15));
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.scrollY > 50);
    };
  
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  const handleMoodChange = () => {
    setCurrentImage(currentImage === aboutimg ? helloImg : aboutimg);
  }

  return (
    <>
      <Navbar className={`header-wrapper ${scrolled ? 'scrolled' : ''}`}>
        <div className="header-container">
          <div className="logo-container-x">
            <span className="logo">ROARZ CODES</span>
          </div>
          <div className="mood-container">
            <button onClick={handleMoodChange}>Change the mood</button>
          </div>
          <div className="contact-element">
            <b>
           <a href="#footer">Contact</a></b>
          </div>
        </div>
      </Navbar>    

      <section className="banner" id="home">
        <div className="cover-header">
          <div className="year">
            <span className="mask">
              <motion.b
                variants={slideUp}
                custom={0}
                initial="initial"
                animate={areYearsInView[0] ? "open" : "closed"}
                ref={yearRefs[0]}
              >
                2015
              </motion.b>
            </span>
            <span className="mask element-r">
              <motion.b
                variants={slideUp}
                custom={1}
                initial="initial"
                animate={areYearsInView[0] ? "open" : "closed"}
                ref={yearRefs[0]}
              >
                12.5
              </motion.b>
            </span>
          </div>
          <div className="cover-center" ref={coverCenterRef}>
            <h1>Freelance <br /> WEB DEVELOPER</h1>
            <span>&apos; bienvenido &apos;</span>
          </div>
          <div className="year">
            <span className="mask element-r">
              <motion.b
                variants={slideUp}
                custom={2}
                initial="initial"
                animate={areYearsInView[1] ? "open" : "closed"}
                ref={yearRefs[1]}
              >
                Free
              </motion.b>
            </span>
            <span className="mask">
              <motion.b
                variants={slideUp}
                custom={3}
                initial="initial"
                animate={areYearsInView[1] ? "open" : "closed"}
                ref={yearRefs[1]}
              >
                2024
              </motion.b>
            </span>
          </div>
        </div>
        <div className="banner-bottom">
          <div className="social-links-container">
            <Link to="https://www.instagram.com/roarz_fx?igsh=djNvMjRpZ2lyNDBhhttps://www.instagram.com/roarz_fx?igsh=djNvMjRpZ2lyNDBh" className="social-link">Instagram</Link>
            <Link to="https://x.com/fx__matrix?s=09" className="social-link">Twitter</Link>
            <Link to="https://ke.linkedin.com/in/eugene-roarz-4869b82b1" className="social-link">Linkedin</Link>
          </div>
          <div className='name-wrapper'>
            <div className="name-container">
              <div>
                <VscDebugBreakpointLog /> I'm Roarz
              </div>
              <img src={currentImage} alt="img" />
            </div>
          </div>
          <div className="last-banner" ref={description}>
            <div>
              <b> UX/UI Designer </b>
              <b>& Developer</b>
            </div>
            <p>
              I’m a freelance web designer and developer. I develop websites that reflect the latest technologies and trends, prioritizing performance and stability.
            </p>
            <span> / / Scroll to Explore</span>
          </div>
        </div>
      </section>
    </>
  );
};
