import React, { useEffect } from 'react';
import gsap from 'gsap';

const Preloader = ({ onAnimationComplete }) => {
  useEffect(() => {
    function startLoader() {
      let counterElement = document.querySelector(".count p");
      let currentValue = 0;

      function updateCounter() {
        if (currentValue < 100) {
          let increment = Math.floor(Math.random() * 10) + 1;
          currentValue += increment;

          if (currentValue > 100) {
            currentValue = 100;
          }

          counterElement.textContent = currentValue;

          if (currentValue < 100) {
            let delay = Math.floor(Math.random() * 200) + 25;
            setTimeout(updateCounter, delay);
          } else {
         
            closeLoader();
          }
        }
      }

      updateCounter();
    }

    function closeLoader() {
      gsap.to(".loader-x", {
        height: "0",
        ease: "power4.inOut",
        duration: 1,
        delay: 1, 
        onComplete: onAnimationComplete 
      });
    }

    startLoader();

    gsap.to(".count", { opacity: 0, delay: 3.5, duration: 0.5 });

    let textWrapper = document.querySelector(".ml16");
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    gsap.timeline({ repeat: 0 })
      .fromTo(".ml16 .letter", {
        translateY: -100,
      }, {
        translateY: 0,
        ease: "power4.out",
        duration: 1.5,
        stagger: 0.03
      })
      .to(".ml16 .letter", {
        translateY: 100,
        ease: "power4.in",
        duration: 0.8, // Shortened duration
        delay: 0.8,
        stagger: 0.02
      });

    gsap.to(".pre-loader", {
      scale: 0.5,
      ease: "power4.inOut",
      duration: 2,
      delay: 3
    });

    gsap.to(".loader-bg", {
      height: "0",
      ease: "power4.inOut",
      duration: 0.8,
      delay: 0.8,
    });

  }, [onAnimationComplete]);

  return (
    <div className='loader-container'>

      <div className='loader-content'>
        <div className='count'><p>0</p></div>
        <div className='copy'>
          <p className='ml16'>Roarz Codes</p>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
