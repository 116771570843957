import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Banner } from "./Banner";

import Myservices from './MyServices';
import { Footer } from './Footer';
import { HeroParallax } from './HeroParallax';
import AboutSection from './AboutSection';
import HorizontalScroll from './HorizontalScroll';

const Home =() => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); 

    return () => clearTimeout(timeout);
  }, []);




  return (
    <div className="App">
        <>
          <Banner />
          <AboutSection/>
          <HorizontalScroll/>
          
          <Myservices/>
          <HeroParallax/>
          
        <Footer/>
        </>

    </div>
  );
}

export default Home;
