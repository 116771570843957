import React, { useEffect, useState, useRef } from "react";
import data from "../assets/data"; // Ensure correct path to your data file
import { ArchiveNavbar } from "./ArchiveNavbar";

export const Archives = () => {
  const galleryRef = useRef(null);
  const containerRef = useRef(null);
  const [items, setItems] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 800);

  useEffect(() => {
    const generateItems = () => {
      const rows = [
        { id: 1, count: 4 },
        { id: 2, count: 3 },
        { id: 3, count: 4 },
      ];
      const newItems = rows.map((row) => {
        return Array.from({ length: row.count }, (_, index) => {
          const itemId = `${row.id}-${index}`;
          const itemData = data.find((v) => v.id === itemId);
          return {
            id: itemId,
            rowId: row.id,
            img: itemData ? itemData.img : null,
            title: itemData ? itemData.title : "",
          };
        });
      });
      setItems(newItems);
    };
    generateItems();

    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isLargeScreen) return;

    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const containerRect = containerRef.current.getBoundingClientRect();
      const centerX = containerRect.width / 2;
      const centerY = containerRect.height / 2;

      const sensitivity = 0.8; // Adjust sensitivity as needed
      const deltaX = (centerX - clientX) / sensitivity;
      const deltaY = (centerY - clientY) / sensitivity;

      // Apply the calculated translation to the gallery (inverted movement)
      galleryRef.current.style.transform = `translate(calc(-50% + ${deltaX}px), calc(-50% + ${deltaY}px))`;
    };

    const container = containerRef.current;
    container.addEventListener("mousemove", handleMouseMove);

    return () => {
      container.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isLargeScreen]);

  return (
    <>
      <ArchiveNavbar />
      <div className="container-x" ref={containerRef}>
        <div
          className="gallery"
          ref={galleryRef}
          style={{ transform: 'translate(-50%, -50%)' }}
        >
          {items.map((row, rowIndex) => (
            <div key={`row-${rowIndex}`} className="row-x">
              {row.map((item) => (
                <div key={item.id} className="item">
                  <div className="preview-img">
                    <img src={item.img} alt={item.title} />
                  </div>
                  <div className="item-content">
                    <p>{item.title}</p>
                    <p className="design">WEB DESIGN</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
