import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Footer } from "./components/Footer";
import Home from './components/Home';
import { Archives } from './components/Archives';
import CustomCursor from './components/CustomCursor';
import Preloader from './components/Preloader';

function App() {
  const [loading, setLoading] = useState(true);

  const handleAnimationComplete = () => {
    setLoading(false); // Set loading to false when animation is complete
  };


  return (
    <Router>
      <div className="App">
        {loading ? (
          <Preloader onAnimationComplete={handleAnimationComplete} />
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/archives" element={<Archives />} />
            </Routes>
         
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
