import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import aboutimg from "../assets/img/fashion.png";
import project1 from "../assets/img/car-cover4.PNG";
import project2 from "../assets/img/photography-10.PNG";
import project3 from "../assets/Videos/Clip8.mp4";
import project4 from "../assets/img/best-sellers.jpeg";
import project5 from "../assets/img/street-4.PNG";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { IoMdArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

const HorizontalScroll = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 784);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 784);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        gsap.registerPlugin(Draggable);

        const timeline = document.querySelector(".timeline");
        const scroller = document.querySelector(".scroller");
        const container = document.querySelector(".horizontal-container");
        const horizontalWrapper = document.querySelector(".horizontal-wrapper");

        let isDragging = false;
        let isScrolling = false;

        const updatePositions = (percentage) => {
            if (!isDesktop) return;

            const translateX = percentage * (container.scrollWidth - window.innerWidth);
            container.style.transform = `translate3d(${-translateX}px, 0, 0)`;

            const timelineWidth = timeline.offsetWidth;
            const scrollerWidth = scroller.offsetWidth;
            const maxDragX = timelineWidth - scrollerWidth;
            const dragX = percentage * maxDragX;
            scroller.style.transform = `translate3d(${dragX}px, -50%, 0)`;
        };

        const handleScroll = () => {
            if (isDragging) return;
            isScrolling = true;

            const scrollPosition = window.scrollY - horizontalWrapper.offsetTop;
            const maxScroll = horizontalWrapper.offsetHeight - window.innerHeight;
            const scrollPercentage = Math.min(Math.max(scrollPosition / maxScroll, 0), 1);

            updatePositions(scrollPercentage);

            isScrolling = false;
        };

        if (isDesktop) {
            window.addEventListener('scroll', handleScroll);

       
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isDesktop]);

    return (
        <section className="horizontal-wrapper">
            <div className="horizontal-container">
                <section id="section-1">
                    <div className="projects-header">
                        <div className="projects-header-container">
                            <div className="element-s">
                                <span>
                                    some <br />selected
                                </span>
                            </div>
                            <div className="pr-header">
                                <h1>Projects</h1>
                       </div>
                            <div className="element-k">
                                <span>
                                    web design <br />UI AND UX
                                </span>
                            </div>
                        </div>
                        <div className="element-2">
                            <b>Selected Projects, <br /> some research and playground</b>
                        </div>
                    </div>

                    { isDesktop ? (
                    <div className="arrow-container">
                    <span><RiArrowRightDoubleFill /> </span>
                </div>
                    ) : (
                        <div className="arrow-container">
                        <span className="element-x">&#40; <IoMdArrowDown />  &#41; </span>
                    </div>  
                    )}

                </section>
   
                <section id="section-2">
                    <Link to="https://fancydrive.onrender.com" className="project-container">
                        <img src={project1} alt="Fashion" />
                        <div className="number">
                            <span>
                                &#123; i &#125;
                            </span>
                        </div>
                        <div className="project-bottom">
                            <div className="project-content">
                                <h1>Fancy Drive</h1>
                                <p>This site is designed to make renting a car a hassle-free and enjoyable experience for every user, whether they're planning a quick weekend getaway or an extensive cross-country adventure. </p>
                                <Link to="https://fancydrive.onrender.com" className="explore-btn">
                              Explore
                            </Link>
                            </div>
                        </div>
                    </Link>
                    <Link to="https://roman-arts.onrender.com" className="project-container">
                        <img src={project2} alt="Fashion" />
                        <div className="number">
                            <span>
                                &#123; ii &#125;
                            </span>
                        </div>
                        <div className="project-bottom">
                            <div className="project-content">
                                <h1>Roman Arts</h1>
                                <p>This photography website is a hub for inspiration and education, celebrating the artistry behind the lens. The website features elegant typography that enhances readability and complements the visual content, creating an immersive experience for visitors.</p>                                <Link to="https://spacepixels.onrender.com"  className="explore-btn">
                              Explore
                            </Link>
                            </div>
                        </div>
                    </Link>
                    <Link to="https://lozano-films.onrender.com" className="project-container">
                        
                        <video autoPlay loop muted>
                            <source src={project3} type="video/mp4"/>
                        </video>



                       
                        <div className="number">
                            <span>
                                &#123; iii &#125;
                            </span>
                        </div>
                        <div className="project-bottom">
                            <div className="project-content">
                                <h1>Lozano Films</h1>
                                <p>The website features a modern and vibrant design, characterized by bold typography and sleek graphics. Its energetic layout conveys a sense of movement, engaging users with a dynamic visual experience.</p>
                                <Link to="https://lozano-films.onrender.com" className="explore-btn">
                              Explore
                            </Link>
                            </div>
                        </div>
                    </Link>
                    <a href="https://www.bestsellersshopke.com" className="project-container">
                        <img src={project4} alt="Fashion" />
                        <div className="number">
                            <span>
                                &#123; iv &#125;
                            </span>
                        </div>
                        <div className="project-bottom">
                            <div className="project-content">
                                <h1>Best Sellers</h1>
                                <p>The office furniture store showcases a stylish and contemporary design, with clean lines and a minimalist aesthetic. The layout is inviting and spacious, allowing customers to explore a diverse range of high-quality pieces that blend functionality with modern elegance.</p>
                                <Link to="https://www.bestsellersshopke.com" className="explore-btn">
                              Explore
                            </Link>
                            </div>
                        </div>
                    </a>
                    <Link to="https://kylian-jay.onrender.com" className="project-container">
                        <img src={project5} alt="Fashion" />
                        <div className="number">
                            <span>
                                &#123; v &#125;
                            </span>
                        </div>
                        <div className="project-bottom">
                            <div className="project-content">
                                <h1>Kylian Jay</h1>
                                <p>The website offers a unique visual experience, where images are creatively masked and unmasked as you scroll. The seamless transitions create a captivating narrative, engaging users with each scroll.</p>
                                <Link to="https://kylian-jay.onrender.com" className="explore-btn">
                              Explore
                            </Link>
                            </div>
                        </div>
                    </Link>
                </section>
            </div>
        </section>
    );
};

export default HorizontalScroll;
