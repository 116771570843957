import photography from "../assets/img/photo.png";
import carapp from "../assets/img/airbnb-5.png";
import slider2 from "../assets/img/easy-eat.jpg";
import restorant from "../assets/img/hotel.png";
import film from "../assets/img/filmp.png";
import ecommerce from "../assets/img/ecomm.jpg";
import fashion from "../assets/img/fashion.png";
import holt from "../assets/img/music.jpg";
import portfolio from "../assets/img/port.jpg";
import amicale from "../assets/img/amicale.jpg";
import realEstate from "../assets/img/amali.jpg";
import ride from "../assets/img/rent.png";

const data = [
  {
    id: "1-0",
    title: "Photography Portfolio",
    img: photography,
  },
  {
    id: "1-1",
    title: "Car Rental",
    img: ride,
  },
  {
    id: "1-2",
    title: "Restaurant",
    img: restorant,
  },
  {
    id: "1-3",
    title: "Film Portfolio",
    img: film,
  },
  {
    id: "2-0",
    title: "E-Commerce Website",
    img: ecommerce,
  },
  {
    id: "2-1",
    title: "Fashion Website",
    img: fashion,
  },
  {
    id: "2-2",
    title: "Askonas Holt",
    img: holt,
  },
  {
    id: "3-0",
    title: "Portfolio",
    img: portfolio,
  },
  {
    id: "3-1",
    title: "Amicale",
    img: amicale,
  },
  {
    id: "3-2",
    title: "Architecture Portfolio",
    img: realEstate,
  },
  {
    id: "3-3",
    title: "Food Website",
    img: slider2,
  },
];

export default data;
