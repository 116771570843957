import svg from "../assets/img/svg.svg";
import { TiTick } from "react-icons/ti";
import { useEffect, useRef, useState } from "react";
import { motion } from 'framer-motion';

const AboutSection = () => {
  
  const phrase2 = "I prioritize tidy layouts, clean typography, and meticulous alignment to ensure user-friendly products. With a passion for minimalism, I aim to maximize the impact of brands' messages, stories, and images in each of my designs.";

  const description = useRef(null);
  const [isTextInView, setTextInView] = useState(false);

  const slideUp = {
    initial: {
      y: "100%",
    },
    open: (i) => ({
      y: "0%",
      transition: { duration: 0.5, delay: 0.01 * i },
    }),
    closed: {
      y: "100%",
      transition: { duration: 0.5 },
    },
  };

  const servicesRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const descriptionRect = description.current.getBoundingClientRect();
      setTextInView(descriptionRect.top < window.innerHeight / 1.15);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const revealElements = servicesRef.current.querySelectorAll("[data-reveal]");

    const scrollReveal = () => {
      for (let i = 0; i < revealElements.length; i++) {
        const elementIsInScreen = revealElements[i].getBoundingClientRect().top < window.innerHeight / 1.15;

        if (elementIsInScreen) {
          revealElements[i].classList.add("revealed");
        } else {
          revealElements[i].classList.remove("revealed");
        }
      }
    };

    window.addEventListener("scroll", scrollReveal);
    scrollReveal(); // Initial check

    return () => {
      window.removeEventListener("scroll", scrollReveal);
    };
  }, []);

  return (
    <>
      <div className="about-container" ref={description}>
        <div className="last-banner-2">
          <div>
            <b>UX/UI Designer</b>
            <b>& Developer</b>
          </div>

          <p>
          I’m a freelance web designer and developer. I develop websites that reflect the latest technologies and trends, prioritizing performance and stability.
          </p>
        </div>

        <div className="about-desc-1">
          <h1>
            <span>
            &#123; What you can expect from me: &#125;
            </span>
          </h1>
          <p>
            <span>
            I prioritize tidy layouts, clean typography, and meticulous alignment to ensure user-friendly products. With a passion for minimalism, I aim to maximize the impact of brands' messages, stories, and images in each of my designs.
            </span>
          </p>
        </div>

        <div className="about-desc-2" ref={servicesRef}>
          <div className="services-element">
            <h1>All Services</h1>
            <ul>
              <li data-reveal><TiTick /> Web design</li>
              <li data-reveal><TiTick /> Art Direction</li>
              <li data-reveal><TiTick /> Mobile Design</li>
              <li data-reveal><TiTick /> Motion Design</li>
              <li data-reveal><TiTick /> Creative Frontend Development</li>
              <li data-reveal><TiTick /> Media publications</li>
              <li data-reveal><TiTick /> Backend Development</li>
            </ul>
          </div>
          <div className="about-element">
            <p>
              <span>
              With a background in training and designing slides for domestic enterprises for a couple of years, I have a deep understanding of layout presentation, content handling, and working with motion effects. Now, I bring that experience to web design along with my knowledge of front-end and back-end programming to create more great websites.

              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
